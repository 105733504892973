/** @jsx jsx */

import { graphql } from "gatsby"

import { jsx, Styled, Text } from "theme-ui"

import Root from "@layouts/root"
import SEO from "@components/seo"

export default function PostTemplate({ data, location }) {
  const page = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  return (
    <Root location={location} title={siteTitle}>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.excerpt}
      />
      <article>
        {page.frontmatter.title !== 'Index' && <Styled.h1>{page.frontmatter.title}</Styled.h1>}
        {page.frontmatter.intro &&  <Text>{page.frontmatter.intro}</Text>}
       
        <Text dangerouslySetInnerHTML={{ __html: page.html }} />
      </article>
    </Root>
  )
}

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        intro
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
